@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  src: url('/static/fonts/montserrat-regular.woff2') format("woff2");
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  src: url('/static/fonts/montserrat-semibold.woff2') format("woff2");
  font-display: swap;
}

@font-face {
  font-family: 'Inter var';
  font-style: normal;
  font-weight: normal;
  src: url('/static/fonts/inter-v11-latin-ext_latin-regular.woff2') format("woff2");
  font-display: swap;
}

@font-face {
  font-family: 'Inter var';
  font-style: normal;
  font-weight: bold;
  src: url('/static/fonts/inter-v11-latin-ext_latin-700.woff2') format("woff2");
  font-display: swap;
}

@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: bold;
  /* src: url('/static/fonts/josefinsans-variablefont_wght-webfont.woff2') format('woff2'); */
  src: url('/static/fonts/josefin-sans-v24-latin-700.woff2') format('woff2');
  /* src: url('/static/fonts/josefinsans-bold.woff2') format('woff2'); */
  font-display: swap;
}

@font-face {
  font-family: 'Habibi';
  font-style: normal;
  font-weight: normal;
  src: url('/static/fonts/habibi-regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Habibi';
  font-style: normal;
  font-weight: bold;
  src: url('/static/fonts/lora-v26-latin-700.woff2') format('woff2');
  font-display: swap;
}

/* @font-face {
  font-family: 'Garamond';
  font-style: normal;
  font-weight: normal;
  src: url('/static/fonts/eb-garamond-v25-latin-regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Garamond';
  font-style: normal;
  font-weight: bold;
  src: url('/static/fonts/eb-garamond-v25-latin-600.woff2') format('woff2');
  font-display: swap;
} */

@font-face {
  font-family: 'Amatic';
  font-style: normal;
  font-weight: bold;
  src: url('/static/fonts/amatic-sc-v24-latin-700.woff2') format('woff2');
  font-display: swap;
}
